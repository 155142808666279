import "core-js/modules/es.array.concat.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-acccff36"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["hidden"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  return _ctx.onlySvg ? (_openBlock(), _createBlock(_component_svg_icon, {
    key: 0,
    iconName: "member-level-icon".concat(_ctx.level),
    class: "icon"
  }, null, 8, ["iconName"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["level", "level".concat(_ctx.level, " ").concat(_ctx.customStyle)]),
    hidden: _ctx.onlySvg
  }, [_createVNode(_component_svg_icon, {
    iconName: "member-level-icon".concat(_ctx.level),
    class: "icon"
  }, null, 8, ["iconName"]), _createElementVNode("span", {
    class: _normalizeClass(["text", "level".concat(_ctx.level, " ").concat(_ctx.customStyle)])
  }, _toDisplayString(_ctx.levelName), 3)], 10, _hoisted_1));
}