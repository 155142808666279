import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0101e4dc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "nav-link register"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "btn",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.onLoginModelToggle && _ctx.onLoginModelToggle.apply(_ctx, arguments);
    })
  }, _toDisplayString(_ctx.t('homepage.login')) + "/" + _toDisplayString(_ctx.t('homepage.register')), 1)]);
}