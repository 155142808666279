import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { defineComponent, ref, computed, inject } from 'vue';
import { ALL_LANGUAGES } from '@/constants';
import Level from '@/components/personal/Level.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  components: (_components = {}, _defineProperty(_components, _Popover.name, _Popover), _defineProperty(_components, _CellGroup.name, _CellGroup), _defineProperty(_components, _Cell.name, _Cell), _defineProperty(_components, "Level", Level), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: {
    logout: {
      type: Function
    },
    fromOrder: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var router = useRouter();
    var currentPath = computed(function () {
      return router.currentRoute.value.path;
    });
    var showPopover = ref(false);
    var locale = inject('locale');
    var store = useStore();
    var userInfo = computed(function () {
      return store.state.account.me;
    });
    var currentIcon = computed(function () {
      var _ALL_LANGUAGES$find;

      return ((_ALL_LANGUAGES$find = ALL_LANGUAGES.find(function (country) {
        return country.countryCode === locale.value;
      })) === null || _ALL_LANGUAGES$find === void 0 ? void 0 : _ALL_LANGUAGES$find.icon) || 'flag-for-taiwan';
    });
    var paths = ref([{
      key: 'order',
      icon: 'icon-24-wallet',
      path: '/personal/wallet',
      label: '我的錢包'
    }, {
      key: 'order',
      icon: 'icon-24-order',
      path: '/personal/order',
      label: '訂單管理'
    }, {
      key: 'account',
      icon: 'icon-24-account',
      path: '/personal/info',
      label: '帳戶管理'
    }, {
      key: 'coins',
      icon: 'icon-24-coins',
      path: '/personal/level',
      label: '會員等級'
    } // {
    //   key: 'discount',
    //   icon: 'icon-24-discount',
    //   path: '/personal/coupon',
    //   label: '我的優惠碼'
    // }
    ]);

    var onPopoverOpen = function onPopoverOpen(e) {
      if (!props.fromOrder) {
        e.stopPropagation();
        showPopover.value = true;
      }
    };

    var onClickOverlay = function onClickOverlay() {
      showPopover.value = false;
    };

    var redirectTo = function redirectTo(path) {
      if (path) {
        window.location.href = path;
      }
    };

    return {
      userInfo: userInfo,
      paths: paths,
      showPopover: showPopover,
      onPopoverOpen: onPopoverOpen,
      onClickOverlay: onClickOverlay,
      currentIcon: currentIcon,
      redirectTo: redirectTo,
      currentPath: currentPath
    };
  }
});