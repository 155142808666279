import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.function.name.js";
import { computed, defineComponent, inject, ref, watch } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  components: _defineProperty({}, _Dialog.name, _Dialog),
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');
    var store = useStore();
    var hotelsLoading = computed(function () {
      return store.state.hotels.hotelsListLoading;
    });
    var hotelDetailLoading = computed(function () {
      return store.state.hotel.hotelDetailLoading;
    });
    var bookingLoading = computed(function () {
      return store.state.booking.bookingLoading;
    });
    var show = ref(hotelsLoading.value || hotelDetailLoading.value || bookingLoading.value);
    watch(hotelsLoading, function (newV, oldV) {
      if (newV !== oldV) {
        show.value = newV;
      }
    });
    watch(hotelDetailLoading, function (newV, oldV) {
      if (newV !== oldV) {
        show.value = newV;
      }
    });
    watch(bookingLoading, function (newV, oldV) {
      if (newV !== oldV) {
        show.value = newV;
      }
    });
    return {
      t: t,
      show: show,
      isMobile: isMobile
    };
  }
});