import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tooltip_icon = _resolveComponent("tooltip-icon");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_field = _resolveComponent("van-field");

  return _openBlock(), _createBlock(_component_van_field, {
    class: _normalizeClass({
      'van-field-custom': true,
      success: _ctx.showSuccess && _ctx.success && _ctx.inputValue
    }),
    type: _ctx.type,
    modelValue: _ctx.inputValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.inputValue = $event;
    }),
    error: _ctx.error || Boolean(_ctx.errorMessage),
    required: _ctx.required,
    label: _ctx.label,
    size: _ctx.size,
    placeholder: _ctx.placeholder,
    maxlength: _ctx.maxlength,
    errorMessage: _ctx.showSuccess && _ctx.success && _ctx.inputValue ? _ctx.t('order.finish') : _ctx.errorMessage,
    autocomplete: _ctx.autocomplete
  }, _createSlots({
    label: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.label), 1), Boolean(_ctx.tooltip) ? (_openBlock(), _createBlock(_component_tooltip_icon, {
        key: 0,
        text: _ctx.tooltip
      }, null, 8, ["text"])) : _createCommentVNode("", true)];
    }),
    "error-message": _withCtx(function () {
      return [_createVNode(_component_svg_icon, {
        iconName: _ctx.success ? 'icon_24_hint_ok' : 'icon_24_hint_attention',
        class: "hint-icon"
      }, null, 8, ["iconName"]), _createTextVNode(_toDisplayString(_ctx.success ? _ctx.t(_ctx.successMsg) : _ctx.errorMessage), 1)];
    }),
    _: 2
  }, [_ctx.selector ? {
    name: "right-icon",
    fn: _withCtx(function () {
      return [_createVNode(_component_svg_icon, {
        class: "caret-down-icon",
        iconName: "icon_24_arrow_caret_down"
      })];
    })
  } : undefined]), 1032, ["class", "type", "modelValue", "error", "required", "label", "size", "placeholder", "maxlength", "errorMessage", "autocomplete"]);
}