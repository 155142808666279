import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1dc8bf44"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "tooltip"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_popover = _resolveComponent("van-popover");

  return _openBlock(), _createBlock(_component_van_popover, {
    show: _ctx.showPopover,
    placement: _ctx.placement
  }, {
    reference: _withCtx(function () {
      return [_createVNode(_component_svg_icon, {
        class: "hint-info",
        iconName: _ctx.iconName,
        onmouseover: _ctx.handleShow,
        onmouseleave: _ctx.handleHide
      }, null, 8, ["iconName", "onmouseover", "onmouseleave"])];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.text), 1)];
    }),
    _: 1
  }, 8, ["show", "placement"]);
}