import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_popup = _resolveComponent("van-popup");

  return _openBlock(), _createBlock(_component_van_popup, {
    show: _ctx.show,
    position: _ctx.position,
    onClickOverlay: _ctx.clickOverlay
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default")];
    }),
    _: 3
  }, 8, ["show", "position", "onClickOverlay"]);
}