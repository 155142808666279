import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { defineComponent, ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { ALL_LANGUAGES } from '@/constants';
export default defineComponent({
  components: {},
  props: ['onLoginModelToggle'],
  setup: function setup() {
    var t = inject('t');
    var store = useStore();
    var showPopover = ref(false);
    var countryCode = computed(function () {
      return store.state.options.countryCode;
    });
    var currentIcon = computed(function () {
      var _ALL_LANGUAGES$find;

      return ((_ALL_LANGUAGES$find = ALL_LANGUAGES.find(function (country) {
        return country.countryCode === countryCode.value;
      })) === null || _ALL_LANGUAGES$find === void 0 ? void 0 : _ALL_LANGUAGES$find.icon) || 'flag-for-taiwan';
    });

    var onPopoverOpen = function onPopoverOpen(e) {
      e.stopPropagation();
      showPopover.value = true;
    };

    var onClickOverlay = function onClickOverlay() {
      showPopover.value = false;
    };

    return {
      t: t,
      showPopover: showPopover,
      onPopoverOpen: onPopoverOpen,
      onClickOverlay: onClickOverlay,
      currentIcon: currentIcon
    };
  }
});