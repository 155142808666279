import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";
import "core-js/modules/es.function.name.js";
import { defineComponent, ref } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'ComPopup',
  components: _defineProperty({
    SvgIcon: SvgIcon
  }, _Popover.name, _Popover),
  props: {
    iconName: {
      type: String,
      default: 'hint-info'
    },
    text: {
      type: String
    },
    placement: {
      default: 'bottom-start',
      type: String
    }
  },
  setup: function setup() {
    var showPopover = ref(false);

    var handleShow = function handleShow() {
      if (!showPopover.value) {
        showPopover.value = true;
      }
    };

    var handleHide = function handleHide() {
      if (showPopover.value) {
        showPopover.value = false;
      }
    };

    return {
      showPopover: showPopover,
      handleShow: handleShow,
      handleHide: handleHide
    };
  }
});