import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7de2e367"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "header"
};
var _hoisted_2 = {
  key: 1,
  class: "placeholder"
};
var _hoisted_3 = {
  class: "title"
};
var _hoisted_4 = {
  key: 3,
  class: "placeholder"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  return _openBlock(), _createElementBlock("header", _hoisted_1, [_ctx.onBack ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "nav-button",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.onBack && _ctx.onBack.apply(_ctx, arguments);
    })
  }, [_createVNode(_component_svg_icon, {
    class: "nav-icon",
    iconName: "nav-arrow"
  })])) : (_openBlock(), _createElementBlock("div", _hoisted_2)), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), _ctx.onClose ? (_openBlock(), _createElementBlock("button", {
    key: 2,
    class: "nav-button",
    onClick: _cache[1] || (_cache[1] = //@ts-ignore
    function () {
      return _ctx.onClose && _ctx.onClose.apply(_ctx, arguments);
    })
  }, [_createVNode(_component_svg_icon, {
    class: "nav-icon",
    iconName: "icon_24_close"
  })])) : (_openBlock(), _createElementBlock("div", _hoisted_4))]);
}