import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a8a60998"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "page"
};
var _hoisted_2 = {
  class: "section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_nav_bar = _resolveComponent("nav-bar");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_nav_bar, {
    onBack: _ctx.onBack,
    onClose: _ctx.onClose
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.title), 1)];
    }),
    _: 1
  }, 8, ["onBack", "onClose"]), _createElementVNode("section", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]);
}