import { AccountState } from '@/store/types/account'
import { computed, reactive, Ref, toRefs } from 'vue'
import { useStore } from 'vuex'

export interface useAuthType {
  userInfo: Ref<AccountState['me']>
  isLoggedIn: Ref<boolean>
}
export const useAuthStore = (): useAuthType => {
  const store = useStore()
  const userInfo = computed(() => store.state.account.me)
  const isLoggedIn = computed(() => userInfo.value.id)

  const value = reactive({
    userInfo,
    isLoggedIn
  })

  return {
    ...toRefs(value)
  }
}
