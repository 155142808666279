import { defineComponent, computed } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'Level',
  components: {
    SvgIcon: SvgIcon
  },
  props: ['onlySvg', 'customStyle'],
  setup: function setup() {
    var store = useStore();
    var levelInfo = computed(function () {
      return store.state.user.levelInfo;
    });
    var level = computed(function () {
      return levelInfo.value.level_id;
    });
    var levelName = computed(function () {
      return levelInfo.value.level_name;
    });
    return {
      level: level,
      levelName: levelName
    };
  }
});