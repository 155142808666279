import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import { computed, defineComponent, inject } from 'vue';
import TooltipIcon from '../share/TooltipIcon.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'ComField',
  components: _defineProperty({
    TooltipIcon: TooltipIcon,
    SvgIcon: SvgIcon
  }, _Field.name, _Field),
  props: {
    type: {
      default: 'text',
      type: String
    },
    label: {
      type: String
    },
    size: {
      default: 'small',
      type: String
    },
    maxlength: {
      type: Number
    },
    tooltip: {
      type: String
    },
    placeholder: {
      type: String
    },
    required: {
      type: Boolean
    },
    error: {
      type: Boolean
    },
    errorMessage: {
      type: String
    },
    autocomplete: {
      type: String
    },
    selector: {
      type: Boolean
    },
    modelValue: {
      type: String
    },
    showSuccess: {
      default: true,
      type: Boolean
    },
    successMsg: {
      default: 'order.finish',
      type: String
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var t = inject('t');
    var success = computed(function () {
      return !props.errorMessage;
    });
    var inputValue = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(val) {
        return emit('update:modelValue', val);
      }
    });
    return {
      t: t,
      success: success,
      inputValue: inputValue
    };
  }
});